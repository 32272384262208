<template>
  <div
    aria-label="Site Menu"
    class="navbar navbar-expand-lg fixed-top text-uppercase bg-nav"
    id="mainNav"
    :class="{ scrolled: isScrolled }"
  >
    <div id="espaHeader" class="col-md-2">
      <a
        class="portfolio-item d-block mx-auto"
        href="#"
        @click="handleImageClick('img/espa.pdf')"
      >
        <img id="espaImg"
          style="height: 100px; width: 200px"
          src="img/espa1420_logo_rgb.jpg"
          alt="Με την Χρηματοδότηση του ΕΣΠΑ"
        />
      </a>
    </div>

    <div id="divNav" LANG="en-US" class="container">
      <router-link
        id="logo"
        class="navbar-brand js-scroll-trigger animated fadeInLeft"
        to="/"
        ><img
          src="img/logo.png"
          alt="Aristea Rent a Car Main"
          style="width: 100px; height: 100px"
        /><strong style="display: none">Logo</strong></router-link
      >
      
      <div class="nav-switch" @click="toggleMainMenu">
        <strong class="fa fa-bars"></strong>
      </div>
      <div aria-label="Related Links" class="main-menu animated fadeInLeft">
        <ul>
          <li>
            <router-link
              class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              to="/"
              v-scroll-to="'#cars'"
              >{{ $t("header.cars") }}</router-link
            >
          </li>
          <li>
            <router-link
              class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              to="/"
              v-scroll-to="'#about'"
              >{{ $t("header.about") }}</router-link
            >
          </li>
          <li>
            <router-link
              class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              to="/"
              v-scroll-to="'#contact'"
              >{{ $t("header.contact") }}</router-link
            >
          </li>
          <li>
            <router-link
              class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              to="/"
              v-scroll-to="'#exploreLemnos'"
              >{{ $t("header.explore") }}</router-link
            >
          </li>
          <li>
            <router-link
              class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              to="/faq"
              >{{ $t("header.faq") }}</router-link
            >
          </li>
          <li>
            <div class="language-option">
              <img :src="`./img/icons/${language}.png`" alt="" />
              <span>
                {{ language.toUpperCase() }} <i class="fa fa-angle-down"></i>
              </span>
              <div class="flag-dropdown">
                <ul>
                  <li>
                    <button
                      @click="changeLanguage(language === 'en' ? 'gr' : 'en')"
                      style="border-style: none; background-color: transparent"
                    >
                      {{ $t(language === "en" ? "Ελληνικά" : "Engish")
                      }}<span class="spanLanguage"></span>
                      <img
                        :src="`./img/icons/${
                          language === 'en' ? 'gr' : 'en'
                        }.png`"
                        alt=""
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="imageModal"
    tabindex="-1"
    aria-labelledby="imageModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div style="height: 890px" class="modal-content">
        <div style="height: 100%" class="modal-body">
          <iframe
            width="100%"
            height="100%"
            title="espa"
            :src="modalImageUrl"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavSection",
  props: {
    msg: String,
  },
  data() {
    return {
      language: "en",
      modalImageUrl: "",
      isScrolled: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleMainMenu() {
      window.$('.main-menu').slideToggle(400);
    },
    changeLanguage(newLanguage) {
      this.$i18n.locale = newLanguage;
      this.language = newLanguage;
      // Add logic to change the language as needed
    },
    handleImageClick(imageUrl) {
      // Set the modal image URL
      this.modalImageUrl = imageUrl;
      // Show the Bootstrap modal
      window.$("#imageModal").modal("show");
    },
    handleScroll() {
      // Get the scroll position
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      // Set a threshold, adjust as needed
      const scrollThreshold = 100;

      // Update isScrolled based on the scroll position
      this.isScrolled = scrollPosition > scrollThreshold;
    },
  },
  // ... (existing code)
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 1115px) {
  #espaImg {
    display: none;
  }
  
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.scrolled {
  background-color: #1f398c !important;
}
.language-option {
  display: inline-block;
  margin-left: 30px;
  cursor: pointer;
  padding: 13px 0px 11px;
  position: relative;
}

.language-option:hover .flag-dropdown {
  top: 40px;
  opacity: 1;
  visibility: visible;
}

.language-option img {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: 10px;
}

.language-option span {
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
}

.language-option .flag-dropdown {
  position: absolute;
  left: 0;
  top: 60px;
  width: 115px;
  background: #ffffff;
  display: block;
  padding: 10px 15px;
  -webkit-box-shadow: 0px 9px 10px rgba(25, 25, 26, 0.2);
  box-shadow: 0px 9px 10px rgba(25, 25, 26, 0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 99;
}

.language-option .flag-dropdown ul li {
  list-style: none;
  text-align: left;
}

.language-option .flag-dropdown ul li a {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #19191a;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.language-option .flag-dropdown ul li a:hover {
  color: #dfa974;
}

.spanLanguage {
  display: inline-block;
  margin: 0px 10px 0px 0px;
}
</style>
