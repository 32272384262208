import { createApp } from 'vue';
import App from './App.vue';
import FaqPage from './FaqPage.vue';
import MainPage from './MainPage.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createI18n } from 'vue-i18n'
import VueScrollTo from 'vue-scrollto';

const i18n = createI18n({
  legacy: false,
  locale: 'en',  // Set your default locale
  messages: {
    en: require('./locales/en.json'),
    gr: require('./locales/gr.json'),
  },
})

const routes = [
  { path: '/', component: MainPage },
  { path: '/faq', component: FaqPage },
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

app.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
});
app.use(router);
app.use(i18n);
app.mount('#app');
