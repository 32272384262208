<template>
  <div class="page-top-area set-bg" style="background-image: url(img/faq.png)">
    <div class="breadcrumb-area"><router-link to="/">{{ $t("header.home") }}</router-link> / <span>{{ $t("header.faq") }}</span></div>
  </div>

  <section id="faq">
    <div class="container">
      <h2
        class="text-center text-uppercase text-secondary mb-0"
        style="font-size: 2rem; line-height: 5.5rem"
      >
      {{ $t("faq.header") }}
      </h2>
      <hr class="" />
      <div class="panel-group" id="accordion">
        <div class="faqHeader"><h3>{{ $t("faq.agree") }}</h3></div>
        <hr class="shadow-lg mb-5" />

        <div class="card">
          <div class="card-header" role="tablist" id="headingOne">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("faq.age") }}</a
              >
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div class="card-body">
              {{ $t("faq.age1") }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tablist" id="headingTwo">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("faq.licence") }}
              </a>
            </h5>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            role="tabpanel"
            aria-labelledby="headingTwo"
          >
            <div class="card-body">
              {{ $t("faq.licence1") }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tablist" id="headingThree">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("faq.time") }}</a
              >
            </h5>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            role="tabpanel"
            aria-labelledby="headingThree"
          >
            <div class="card-body">
              {{ $t("faq.time1") }}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" role="tablist" id="heading4">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse4"
                aria-expanded="false"
                aria-controls="collapse4"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("faq.fee") }}</a
              >
            </h5>
          </div>

          <div
            id="collapse4"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading4"
          >
            <div class="card-body">
              {{ $t("faq.fee1") }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tablist" id="heading5">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse5"
                aria-expanded="false"
                aria-controls="collapse5"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong
                >{{ $t("faq.insr") }}
              </a>
            </h5>
          </div>
          <div
            id="collapse5"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading5"
          >
            <div class="card-body">
              {{ $t("faq.insr1") }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tablist" id="heading6">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse6"
                aria-expanded="false"
                aria-controls="collapse6"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong
                >{{ $t("faq.gasoline") }}</a
              >
            </h5>
          </div>
          <div
            id="collapse6"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading6"
          >
            <div class="card-body">{{ $t("faq.gasoline1") }}</div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" role="tablist" id="heading7">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse7"
                aria-expanded="false"
                aria-controls="collapse7"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong
                >{{ $t("faq.cancel") }}</a
              >
            </h5>
          </div>

          <div
            id="collapse7"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading7"
          >
            <div class="card-body">{{ $t("faq.cancel1") }}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tablist" id="heading8">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse8"
                aria-expanded="false"
                aria-controls="collapse8"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("faq.change") }}
              </a>
            </h5>
          </div>
          <div
            id="collapse8"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading8"
          >
            <div class="card-body">
              {{ $t("faq.change1") }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tablist" id="heading9">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse9"
                aria-expanded="false"
                aria-controls="collapse9"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("faq.drivehelp") }}</a
              >
            </h5>
          </div>
          <div
            id="collapse9"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading9"
          >
            <div class="card-body">{{ $t("faq.drivehelp1") }}</div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" role="tablist" id="heading10">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse10"
                aria-expanded="false"
                aria-controls="collapse10"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong
                >{{ $t("faq.tax") }}</a
              >
            </h5>
          </div>

          <div
            id="collapse10"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading10"
          >
            <div class="card-body">{{ $t("faq.tax1") }}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tablist" id="heading11">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse11"
                aria-expanded="false"
                aria-controls="collapse11"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong
                >{{ $t("faq.charge") }}
              </a>
            </h5>
          </div>
          <div
            id="collapse11"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading11"
          >
            <div class="card-body">
              <STRONG> {{ $t("faq.charge1") }} </STRONG>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tablist" id="heading12">
            <h5 class="mb-0">
              <a
                class="collapsed text-white"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse12"
                aria-expanded="false"
                aria-controls="collapse12"
                ><strong class="fas fa-arrow-circle-right pr-2"></strong
                >{{ $t("faq.insramount") }}</a
              >
            </h5>
          </div>
          <div
            id="collapse12"
            class="collapse"
            role="tabpanel"
            aria-labelledby="heading12"
          >
            <div class="card-body">
              {{ $t("faq.insramount1") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "FaqSection",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
