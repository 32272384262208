<template>
  <section class="portfolio" id="cars">
    <div class="container">
      <h2
        class="text-center text-uppercase text-secondary mb-0"
        style="font-size: 2rem; line-height: 2.5rem"
      >
      {{ $t("header.cars") }} &nbsp;
      </h2>
      <hr class="" />
      <div class="row">
        <div class="col-md-6 col-lg-4 text-center">
          <a
            class="portfolio-item d-block mx-auto"
            href="#"
            @click="
              handleImageClick(
                'img/portfolio/Opel-Crossland-Edition-2021-4.jpg',
                'Opel Crossland 2021',
                'Fuelengine 1.2-lt Turbo 130 HP'
              )
            "
          >
            <div
              class="portfolio-item-caption d-flex position-absolute h-100 w-100"
            >
              <div
                class="portfolio-item-caption-content my-auto w-100 text-center text-white"
              >
                <strong class="fas fa-search-plus fa-3x"></strong>
              </div>
            </div>
            <img
              alt="Rent Kia Picanto White"
              class="img-fluid"
              src="img/portfolio/Opel-Crossland-Edition-2021-4.jpg"
            />
            <strong>Opel Crossland 2021</strong>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 text-center">
          <a
            class="portfolio-item d-block mx-auto"
            href="#"
            @click="
              handleImageClick(
                'img/portfolio/kia-picanto-white.png',
                'Kia Picanto MY2019',
                'Fuelengine 1.0-lt 67 HP'
              )
            "
          >
            <div
              class="portfolio-item-caption d-flex position-absolute h-100 w-100"
            >
              <div
                class="portfolio-item-caption-content my-auto w-100 text-center text-white"
              >
                <strong class="fas fa-search-plus fa-3x"></strong>
              </div>
            </div>
            <img
              alt="Rent Kia Picanto White"
              class="img-fluid"
              src="img/portfolio/kia-picanto-white.png"
            />
            <strong>Kia Picanto MY2019</strong>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 text-center">
          <a
            class="portfolio-item d-block mx-auto"
            href="#"
            @click="
              handleImageClick(
                'img/portfolio/fiat-panda-white.png',
                'Fiat Panda CNG',
                'Fuelengine 1.2-lt 69 HP'
              )
            "
          >
            <div
              class="portfolio-item-caption d-flex position-absolute h-100 w-100"
            >
              <div
                class="portfolio-item-caption-content my-auto w-100 text-center text-white"
              >
                <strong class="fas fa-search-plus fa-3x"></strong>
              </div>
            </div>
            <img
              src="img/portfolio/fiat-panda-white.png"
              alt="Rent Fiat Panda White"
            />
            <strong>Fiat Panda CNG</strong>
          </a>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="portfolioModal"
      tabindex="-1"
      aria-labelledby="portfolioModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content" style="width: 80%;">
          <a
            class="close-button d-none d-md-block portfolio-modal-dismiss"
            data-dismiss="modal"
          >
            <strong class="fa fa-3x fa-times" style="margin: 15px 15px auto;"></strong>
          </a>
          <div class="container text-center">
            <div class="col-lg-8 mx-auto">
              <p class="text-secondary text-uppercase mb-0">{{ modalTitle }}</p>
              <hr class="shadow-lg" />
              <img
                :src="modalImageUrl"
                class="img-fluid mb-5"
                :alt="modalTitle"
              />
              <p class="text-secondary text-uppercase mb-5">{{ $t("cars.desc1") }}</p>
              <div class="col-lg-8 mx-auto">
                <p class="text-secondary text-uppercase mb-5">{{ desc }}</p>
                <img
                  src="img/mileage.png"
                  width="64"
                  alt="an image for car mileage "
                />&nbsp;{{ $t("cars.desc2") }}
                <img
                  src="img/fuel.png"
                  width="64"
                  alt="an image for car fuel"
                />&nbsp;{{ $t("cars.desc3") }}
                <img
                  src="img/seat.png"
                  width="64"
                  alt="an image for car seats"
                />&nbsp;{{ $t("cars.desc4") }}
                <img
                  src="img/doors.png"
                  width="64"
                  alt="an image for car doors"
                />&nbsp;{{ $t("cars.desc5") }}
                <img
                  src="img/bag.png"
                  width="64"
                  alt="an image for bag"
                />&nbsp;{{ $t("cars.desc6") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CarsSection",
  props: {
    msg: String,
  },
  data() {
    return {
      modalImageUrl: "",
      modalTitle: "",
      desc: "",
    };
  },
  methods: {
    handleImageClick(imageUrl, title, desc) {
      this.modalImageUrl = imageUrl;
      this.modalTitle = title;
      this.desc = desc;
      window.$("#portfolioModal").modal("show");
    },
  },
};
</script>

<style scoped></style>
