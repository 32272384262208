<template>
  <div style="overflow-x: hidden;">
   <RouterView></RouterView>
</div>
</template>

<script>


export default {
  name: 'App',
  components: {
   
},
};
</script>

<style scoped>
  /* Scoped styles specific to this component */
</style>
