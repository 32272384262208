<template>
  <section style="padding: 0rem 0; background-color: aliceblue">
    <h3
      class="text-center text-uppercase text-white mb-0"
      style="display: none; font-size: 2rem; line-height: 2.5rem"
    >
      Video of lemnos Map
    </h3>

    <div class="video-container" id="#exploreLemnos">
      <video
        id="video"
        controls
        preload="metadata"
        autoplay
        muted
        loop
        width="1280"
        height="400"
      >
        <source src="video/lemnos.mp4" type="video/mp4" />
        <source src="video/lemnos.ogg" type="video/ogg" />
        <track
          label="English"
          kind="captions"
          srclang="en"
          default
          src="video/lemnos.vtt"
        />
      </video>
    </div>
  </section>
  <p style="text-align: center; background-color: #ffffff; color: #000000">
    <a target="_blank" rel="noopener noreferrer" href="https://en.aegeanair.com"
      >{{ $t("video.source") }}</a
    >
  </p>
</template>

<script>
export default {
  name: "VideoSection",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
