<template>
    <div>
      <NavSection></NavSection>
      <FaqSection></FaqSection>
      <FooterSection></FooterSection>
      <EspaSection></EspaSection>
    </div>
  </template>
  
  <script>
  import NavSection from './components/NavSection.vue';
  import FaqSection from './components/FaqSection.vue';
  import FooterSection from './components/FooterSection.vue';
  import EspaSection from './components/EspaSection.vue';
  
  export default {
    name: 'FaqPage',
    components: {
      NavSection,
      FaqSection,
      FooterSection,
      EspaSection,
  }
  };
  </script>
  
  <style scoped>
    /* Scoped styles specific to this component */
  </style>
  