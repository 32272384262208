<template>
  <footer class="bg-secondary footer text-center">
    <div class="container bg-secondary">
      <div class="row bg-secondary">
        <div class="col-md-3 mb-3 mb-lg-0">
          <p style="color: #ffffff" class="text-uppercase mb-4">{{ $t("footer.loc") }}</p>
          <p style="color: #ffffff" class="lead mb-0">
            <router-link  class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" to="/" v-scroll-to="'#mapAstron'">
                <button
                class="btn btn-outline-light text-center js-scroll-trigger text-inform">
                <strong class="fas fa-map-marker-alt pr-2"></strong>
                {{ $t("contact.address") }}
              </button></router-link>
          </p>
        </div>
        <div class="col-md-6 mb-3 mb-lg-0">
          <p style="color: #ffffff" class="text-uppercase mb-4">{{ $t("footer.contact") }}</p>
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <p>
                <strong class="fas fa-mail-bulk pr-2"></strong>
                <a class="text-inform" href="mailto:aristearentacar@gmail.com"
                  >aristearentacar@gmail.com</a
                >
              </p>
            </li>

            <li class="list-inline-item">
              <p>
                <strong class="fas fa-mobile pr-2"></strong
                ><a
                  class="text-inform"
                  style="text-align: center"
                  href="tel:+306981583591 "
                  ><span style="color: #ffffff">+306981583591 |</span></a
                ><a
                  class="text-inform"
                  style="text-align: center"
                  href="tel:+306978334581 "
                  ><span style="color: #ffffff" class="">6978334581</span></a
                ><br />
                <strong class="fas fa-phone pr-2"></strong
                ><a
                  class="text-inform"
                  style="text-align: center"
                  href="tel:+302254024396"
                  >+30 2254024396</a
                >
              </p>
              <br />
              <p>
                <strong class="fas fa-hotel pr-2"></strong>
                <a
                  class="text-inform"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.astronlemnos.gr"
                >
                {{ $t("footer.hotel") }}</a
                >
              </p>
            </li>
          </ul>
        </div>
        <div class="col-md-3 mb-3 mb-lg-0">
          <p style="color: #ffffff" class="text-uppercase mb-4">{{ $t("footer.found") }}</p>
          <div class="lead mb-0">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <button
                  class="btn btn-outline-light btn-social text-center rounded-circle"
                  rel="noopener noreferrer"
                  onclick="location.href='https://www.facebook.com/pages/category/Car-Rental/AristeaRentaCar-2019126278384595/'"
                >
                  <strong class="fab fa-fw fa-facebook-f"></strong>
                </button>
              </li>
              <li class="list-inline-item">
                <button
                  class="btn btn-outline-light btn-social text-center rounded-circle"
                  rel="noopener noreferrer"
                  onclick="location.href='https://www.facebook.com/pages/category/Car-Rental/AristeaRentaCar-2019126278384595/'"
                >
                  <strong class="fab fa-fw fa-facebook-messenger"></strong>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
