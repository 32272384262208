<template>
  <section class="bg-secondary text-white mb-0" id="whyus">
    <div class="container">
      <h3
        class="text-center text-uppercase text-white"
        style="font-size: 2rem; line-height: 2.5rem"
      >
      {{ $t("why.header") }}&nbsp;&nbsp;
      </h3>
      <hr class="" />
      <div class="row">
        <div class="col-lg-12 ml-auto">
          <p class="lead">
            <strong class="fas fa-arrow-circle-right pr-2"> </strong>{{ $t("why.p1") }}
          </p>
        </div>
        <div class="col-lg-12 mr-auto">
          <p class="lead">
            <strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("why.p2") }}
          </p>
        </div>
        <div class="col-lg-12 mr-auto">
          <p class="lead">
            <strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("why.p3") }}
          </p>
        </div>
        <div class="col-lg-12 ml-auto">
          <p class="lead">
            <strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("why.p4") }}
          </p>
        </div>
        <div class="col-lg-12 mr-auto">
          <p class="lead">
            <strong class="fas fa-arrow-circle-right pr-2"></strong>{{ $t("why.p5") }}
          </p>
        </div>
      </div>
      <hr class="shadow-lg" />
      <div class="row">
        <div class="col-lg-12">
          <p
            class="text-center text-uppercase text-white"
            style="text-align: center"
          >
          {{ $t("why.looking") }}
          </p>
        </div>
        <hr class="shadow-lg" />
        <div class="col-lg-12 col-lg-12-6 text-center lg-12">
          <img
            class="rounded-circle img-fluid d-block mx-auto"
            src="img/astron.jpg"
            id="img"
            alt=""
          />
          <hr class="shadow-lg" />
          <p style="display: none">{{ $t("about.header") }}</p>
          <p>{{ $t("why.hotel") }}</p>
          <hr class="shadow-lg" />
          <p class="my-4 pb-2 text">
            <strong class="fas fa-map-marker-alt pr-2"></strong>
            <a class="js-scroll-trigger" href="#mapAstron"
              >{{ $t("why.address") }}</a
            >
          </p>
          <p class="my-4 pb-2 text">
            <strong class="fas fa-mobile pr-2"></strong>
            <a href="tel:+306981583591 "><span>+306981583591 |</span></a
            ><a href="tel:+306978334581 "><span class=""> 6978334581</span></a>
          </p>
          <p class="my-4 pb-2 text">
            <strong class="fas fa-hotel pr-2"></strong>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.astronlemnos.gr"
            >
            {{ $t("why.hotel") }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhyToChooseSection",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
