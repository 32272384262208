<template>
  <div class="copyright py-4 text-center text-white">
    <div class="container-footer">
      <div class="row">
        <div class="col-md-3 mb-3 mb-lg-0">
          <a
            class="portfolio-item d-block mx-auto"
            href="#"
            @click="handleImageClick('img/espa.pdf')"
          >
            <img
              style="height: 100px; padding-left: 10px; width: 200px"
              src="img/espa1420_logo_rgb.jpg"
              alt="Με την Χρηματοδότηση του ΕΣΠΑ"
            />
          </a>
        </div>
        <div class="col-md-6 mb-3 mb-lg-0">
          <p style="font-size: 1rem; color: #ffffff; margin: 10px 10px auto;" class="my-4 pb-2">
            Copyright &copy; Aristea Rent a Car {{ yearCreated }} -
            {{ currentYear }}
            | {{ $t("footer.developed") }}
            <a
              class="text"
              style="font-size: medium"
              href="mailto:giorgos.giakoumis91@gmail.com"
            >
              George Giakoumis
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="imageModal"
    tabindex="-1"
    aria-labelledby="imageModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div style="height: 890px;"  class="modal-content">
        <div style="height: 100%" class="modal-body">
          <iframe
            width="100%"
            height="100%"
            title="espa"
            :src="modalImageUrl"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EspaSection",
  props: {
    msg: String,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      yearCreated: 2019,
      modalImageUrl: "",
    };
  },
  methods: {
    handleImageClick(imageUrl) {
      // Set the modal image URL
      this.modalImageUrl = imageUrl;
      // Show the Bootstrap modal
      window.$('#imageModal').modal('show');
    },
  },
};
</script>

<style scoped></style>
