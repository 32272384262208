<template>
  <section class="my-5" id="contact">
    <div class="container">
      <h3
        class="text-center text-uppercase text-secondary mb-0"
        style="font-size: 2rem; line-height: 2.5rem"
      >
        {{ $t("header.contact") }}
      </h3>
      <hr class="" />
      <div class="card">
        <!-- Grid row -->
        <div class="row">
          <form
            name="sentMessage"
            id="contactForm"
            @submit.prevent="submitForm"
            style="flex: 1 0 58.666667%; max-width: 99.666667%"
          >
            <div class="col-lg-8">
              <div class="card-body form">
                <p class="mt-4 text-dark">
                  <strong class="fas fa-envelope pr-2"></strong
                  >{{ $t("contact.h2") }}
                </p>
                <hr class="shadow-sm" />

                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input
                        v-model="formData.name"
                        tabindex="1"
                        class="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        :class="{ 'is-invalid': errors.name }"
                        required
                      />
                      <label for="name" class="">{{
                        $t("contact.name")
                      }}</label>
                      <div class="invalid-feedback">{{ errors.name }}</div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input
                        v-model="formData.email"
                        class="form-control"
                        id="email"
                        type="email"
                        placeholder="Email Address"
                        :class="{ 'is-invalid': errors.email }"
                        required
                      />
                      <label for="email" class="">{{
                        $t("contact.email")
                      }}</label>
                      <div class="invalid-feedback">{{ errors.email }}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input
                        v-model="formData.phone"
                        class="form-control"
                        id="phone"
                        type="number"
                        placeholder="Phone Number"
                        :class="{ 'is-invalid': errors.phone }"
                        required
                      />
                      <label for="phone" class="">{{
                        $t("contact.phone")
                      }}</label>
                      <div class="invalid-feedback">{{ errors.phone }}</div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <select
                        v-model="formData.subject"
                        name="subject"
                        type="text"
                        id="subject"
                        class="form-control"
                        :class="{ 'is-invalid': errors.subject }"
                        required
                      >
                        <option disabled value="">
                          {{ $t("contact.subject") }}
                        </option>
                        <option value="Car Availability">
                          {{ $t("contact.cars") }}
                        </option>
                        <option value="Prices">
                          {{ $t("contact.prices") }}
                        </option>
                        <option value="Other">{{ $t("contact.other") }}</option>
                      </select>
                      <label for="subject" class="">{{
                        $t("contact.subject")
                      }}</label>
                      <div class="invalid-feedback">{{ errors.subject }}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="md-form mb-0">
                      <textarea
                        v-model="formData.message"
                        class="form-control"
                        id="message"
                        rows="5"
                        placeholder="Message"
                        :class="{ 'is-invalid': errors.message }"
                        required
                      ></textarea>
                      <label for="message">{{ $t("contact.message") }}</label>
                      <br />
                      <button
                        id="sendMessageButton"
                        type="submit"
                        class="g-recaptcha btn btn-primary btn-xl btn-animation"
                      >
                        {{ $t("contact.submit") }}
                      </button>
                      <div class="invalid-feedback">{{ errors.message }}</div>
                    </div>

                    <div>
                      <div :class="getResponseMessageClass" style="margin: 10px auto;">
                        {{ responseMessage }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->

      <!-- Grid column -->

      <!-- Grid column -->

      <div
        style="background-color: #1f398c !important"
        class="card-body form text-center h-100 white-text bg-secondary"
      >
        <p class="my-4 pb-2 text-inform">{{ $t("contact.info") }}</p>
        <hr class="shadow-sm" />
        <ul class="text-lg-center list-unstyled ml-lg-auto">
          <li>
            <p class="my-4 pb-2 text-inform">
              <router-link  class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" to="/" v-scroll-to="'#mapAstron'">
                <button
                class="btn btn-outline-light text-center js-scroll-trigger text-inform">
                <strong class="fas fa-map-marker-alt pr-2"></strong>
                {{ $t("contact.address") }}
              </button></router-link>
              
            </p>
          </li>
          <li>
            <p class="my-4 pb-2 text-inform" style="font-size: x-large">
              <strong class="fas fa-mobile pr-2"></strong
              ><a
                class="text-inform"
                style="text-align: center"
                href="tel:+306981583591 "
                ><span>+306981583591 |</span></a
              ><a
                class="text-inform"
                style="text-align: center"
                href="tel:+306978334581 "
                ><span class=""> 6978334581</span></a
              >
              <br />
              <strong class="fas fa-phone pr-2"></strong
              ><a
                class="text-inform"
                style="text-align: center"
                href="tel:+302254024396"
                >+30 2254024396</a
              >
            </p>
          </li>
          <li>
            <p class="my-4 pb-2 text-inform">
              {{ $t("contact.sendEmail") }}<br />
              <strong class="fas fa-mail-bulk pr-2"></strong>
              <a class="text-inform" href="mailto:aristearentacar@gmail.com">
                aristearentacar@gmail.com</a
              >
            </p>
          </li>
          <li>
            <p class="my-4 pb-2 text-inform">
              {{ $t("contact.need") }}<br />
              <strong class="fas fa-hotel pr-2"></strong>
              <a
                class="text-inform"
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.astronlemnos.gr"
              >
                {{ $t("contact.hotel") }}</a
              >
            </p>
          </li>
        </ul>
        <hr class="hr-light my-4" />
        <ul class="list-inline text-center list-unstyled">
          <li class="list-inline-item">
            <button
              class="btn btn-outline-light btn-social text-center rounded-circle"
              rel="noopener noreferrer"
              onclick="location.href='https://www.facebook.com/pages/category/Car-Rental/AristeaRentaCar-2019126278384595/'"
            >
              <strong class="fab fa-fw fa-facebook-f"></strong>
            </button>
          </li>
          <li class="list-inline-item">
            <button
              class="btn btn-outline-light btn-social text-center rounded-circle"
              rel="noopener noreferrer"
              onclick="location.href='https://www.facebook.com/pages/category/Car-Rental/AristeaRentaCar-2019126278384595/'"
            >
              <strong class="fab fa-fw fa-facebook-messenger"></strong>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, computed } from "vue";
import classnames from "classnames";
const apiEndpoint = process.env.CONTACT_ENDPOINT;
export default {
  name: "ContactSection",
  props: {
    msg: String,
  },
  setup() {
    const i18n = useI18n();
    const responseMessage = ref("");
    const setResponseMessage = (message) => {
      responseMessage.value = message;
    };

    const getResponseMessageClass = computed(() => {
      return classnames({
        alert: true,
        "alert-success": responseMessage.value === i18n.t("contact.success"),
        "alert-warning": responseMessage.value === i18n.t("contact.warning"),
        "alert-danger": responseMessage.value === i18n.t("contact.alert3"),
      });
    });

    return {
      responseMessage,
      setResponseMessage,
      t: i18n.t, // make the t function available in the template
      getResponseMessageClass,
    };
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      errors: {},
      message: "",
    };
  },
  methods: {
    async submitForm() {
      this.validateForm();

      if (Object.keys(this.errors).length === 0) {
        fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Basic " + btoa('superUser:mySecretCombinationItsAMysteryForYou')
          },
          body: JSON.stringify(this.formData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            // Handle the JSON response
            console.log("API response:", data);
            if (data.code === 0) {
              this.setResponseMessage(this.t("contact.success"));
              // Additional actions for success response
            } else if (data.code === 400) {
              this.setResponseMessage(this.t("contact.warning"));
              // Additional actions for error response
            } else {
              this.setResponseMessage(this.t("contact.alert3"));
            }
            // Rest of your code
          })
          .catch((error) => {
            console.error("API error:", error);
            // Handle the error
            this.setResponseMessage(this.t("contact.alert3"));
          });
      }
    },
    validateForm() {
      this.errors = {};

      if (!this.formData.name) {
        this.errors.name = "Please enter your name.";
      }

      if (!this.formData.email) {
        this.errors.email = "Please enter your email address.";
      } else if (!this.isValidEmail(this.formData.email)) {
        this.errors.email = "Please enter a valid email address.";
      }

      if (!this.formData.phone) {
        this.errors.phone = "Please enter your phone number.";
      }

      if (!this.formData.subject) {
        this.errors.subject = "Please choose a subject.";
      }

      if (!this.formData.message) {
        this.errors.message = "Please enter a message.";
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
