<template>
  <section class="bg-primary text-white mb-0" id="about">
    <div class="container">
      <h3
        data-aos="fade-up"
        class="text-center text-uppercase text-white"
        style="font-size: 2rem; line-height: 2.5rem"
      >
      {{ $t("about.header") }}
      </h3>
      <hr class="" />
      <div class="row">
        <div class="col-lg-4 ml-auto">
          <p class="lead">
            {{ $t("about.p1") }}
          </p>
        </div>
        <div class="col-lg-4 mr-auto">
          <p class="lead">
            {{ $t("about.p2") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "AboutSection",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
