<template>
  <header>
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner" role="listbox">
        <!-- Slide One - Set the background image for this slide in the line below -->
        <div
          class="carousel-item active"
          style="
            background-image: url('img/2017-Kia-Picanto-GT-Line-V5-1080.jpg');
          "
        >
          <div class="carousel-caption d-none d-md-block">
            <div class="has-animation animation-rtl" data-delay="300">
              <p
                class="bigger"
                style="
                  color: #1037b5;
                  text-align: center;
                  padding-bottom: 0px;
                  padding-right: 0px;
                "
              >
                <small
                  style="
                    color: #ffffff;
                    display: contents;
                    background: rgba(0, 0, 0, 0.69);
                  "
                  >{{ $t('welcome') }}</small
                >
                <br />
                <strong>
                  <svg viewBox="0 0 2900 800">
                    <symbol id="s-text">
                      <text
                        text-anchor="middle"
                        x="50%"
                        y="35%"
                        class="webcoderskull"
                      >
                        Aristea
                      </text>
                      <text
                        text-anchor="middle"
                        x="50%"
                        y="68%"
                        class="text--line"
                      >
                        {{ $t('rent_a_car') }}
                      </text>
                    </symbol>

                    <g class="g-ants">
                      <use xlink:href="#s-text" class="webcoderskull-1"></use>
                      <use xlink:href="#s-text" class="webcoderskull-1"></use>
                      <use xlink:href="#s-text" class="webcoderskull-1"></use>
                      <use xlink:href="#s-text" class="webcoderskull-1"></use>
                      <use xlink:href="#s-text" class="webcoderskull-1"></use>
                    </g>
                  </svg>
                </strong>
              </p>
            </div>
            <div class="has-animation animation-ltr" data-delay="700">
              <p class="text" style="background: rgba(0, 0, 0, 0.69)">
                {{ $t('explore') }}
              </p>
              <div class="col-lg-12 ml-auto">
                <p class="text">
                  <strong>{{ $t('call_us') }}</strong><br />
                  <strong class="fas fa-mobile pr-2"></strong
                  ><a style="text-align: center" href="tel:+306981583591 "
                    ><span>306981583591</span></a
                  ><a style="text-align: center" href="tel:+306978334581 "
                    ><span class=""> 6978334581</span></a
                  >
                  <br />
                  <strong class="fas fa-phone pr-2"></strong
                  ><a style="text-align: center" href="tel:+302254024396"
                    >302254024396</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "HeaderSection",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
