<template>
    <div>
      <NavSection></NavSection>
      <HeaderSection></HeaderSection>
      <CarsSection></CarsSection>
      <AboutSection></AboutSection>
      <WhyToChooseSection></WhyToChooseSection>
      <ContactSection></ContactSection>
      <LocationSection></LocationSection>
      <VideoSection></VideoSection>
      <ExploreSection></ExploreSection>
      <FooterSection></FooterSection>
      <NavigateToTopButton></NavigateToTopButton>
      <EspaSection></EspaSection>
  </div>
  </template>
  
  <script>
  import NavSection from './components/NavSection.vue';
  import HeaderSection from './components/HeaderSection.vue';
  import CarsSection from './components/CarsSection.vue';
  import AboutSection from './components/AboutSection.vue';
  import WhyToChooseSection from './components/WhyToChooseSection.vue';
  import LocationSection from './components/LocationSection.vue';
  import VideoSection from './components/VideoSection.vue';
  import ContactSection from './components/ContactSection.vue';
  import ExploreSection from './components/ExploreSection.vue';
  import FooterSection from './components/FooterSection.vue';
  import NavigateToTopButton from './components/NavigateToTopButton.vue';
  import EspaSection from './components/EspaSection.vue';
  
  export default {
    name: 'App',
    components: {
      NavSection,
      HeaderSection,
      CarsSection,
      AboutSection,
      WhyToChooseSection,
      ContactSection,
      LocationSection,
      VideoSection,
      ExploreSection,
      FooterSection,
      NavigateToTopButton,
      EspaSection,
  },
  };
  </script>
  
  <style scoped>
    /* Scoped styles specific to this component */
  </style>
  