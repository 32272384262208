<template>
  <section
    style="background-color: #ffffff"
    class="portfolio-section"
    id="exploreLemnos"
  >
    <h3
      class="text-center text-uppercase text-secondary mb-0"
      style="font-size: 2rem; line-height: 2.5rem"
    >
    {{ $t("explore.header") }}
      
    </h3>
    <hr class="shadow-lg" />
    <div class="sp-pad spad">
      <!-- portfolio filter menu -->
      <ul class="portfolio-filter controls">
        <li style="color: black" class="control" @click="filterImages('all')">
          {{ $t("explore.all") }}
        </li>
        <li style="color: black" class="control" @click="filterImages('old')">
          {{ $t("explore.arxaiolog") }}        </li>
        <li style="color: black" class="control" @click="filterImages('beach')">
          {{ $t("explore.beaches") }}
        </li>
        <li style="color: black" class="control" @click="filterImages('land')">
          {{ $t("explore.landscapes") }}
        </li>
      </ul>
    </div>
    <div class="portfolio-warp">
      <!-- single item -->
      <div
        v-for="(item, index) in filteredImages"
        :key="index"
        class="mix single-portfolio"
        :class="['set-bg', item.category]"
        :style="{ backgroundImage: 'url(' + item.image + ')' }"
      >
        <a
          :href="item.image"
          class="portfolio-item d-block mx-auto portfolio-info"
        >
          <div :class="'pfbg set-bg ' + item.category"></div>
          <p>{{ item.title }}</p>
          <p>{{ item.description }}</p>
        </a>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="sp-pad next-portfolio-link">
      <router-link  class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" to="/" v-scroll-to="'#exploreLemnos'">
        <button class="arrow-btn">
        <strong class="fa fa-angle-right"></strong>
        <strong class="fa fa-angle-right"></strong>
        <strong class="fa fa-angle-right"></strong>
      </button></router-link>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "ExploreSection",
  setup() {
    const i18n = useI18n();
    return {
      t: i18n.t, 
    };
  },
  data() {
    return {
      images: [
        {
          image: "img/lemnos/myrina1.jpg",
          title: this.t("explore.romeikos"),
          description: this.t("explore.romeikos1"),
          category: "land",
        },{
          image: "img/lemnos/oldport.jpg",
          title: this.t("explore.oldport"),
          description: this.t("explore.oldport1"),
          category: "land",
        },
        {
          image: "img/lemnos/kastro.jpg",
          title: this.t("explore.kastro"),
          description: this.t("explore.kastro1"),
          category: "old",
        },
        {
          image: "img/lemnos/myrina2.jpg",
          title: this.t("explore.alleys"),
          description: this.t("explore.alleys1"),
          category: "land",
        },
        {
          image: "img/lemnos/thanos.jpg",
          title: this.t("explore.thanos"),
          description: this.t("explore.thanos1"),
          category: "beach",
        },
        {
          image: "img/lemnos/hfaisteia.jpg",
          title: this.t("explore.hefaisteia"),
          description: this.t("explore.hefaisteia1"),
          category: "old",
        },
        {
          image: "img/lemnos/Filoctetes-Cave-Limnos.jpg",
          title: this.t("explore.philo"),
          description: this.t("explore.philo1"),
          category: "old",
        },
        {
          image: "img/lemnos/kabeiria.jpg",
          title: this.t("explore.kaviria"),
          description: this.t("explore.kaviria1"),
          category: "old",
        },
        {
          image: "img/lemnos/rixanera.jpg",
          title: this.t("explore.rixa"),
          description: this.t("explore.rixa1"),
          category: "beach",
        },
        {
          image: "img/lemnos/karavi.jpg",
          title: this.t("explore.karavi"),
          description: this.t("explore.karavi1"),
          category: "beach",
        },
        {
          image: "img/lemnos/panagia.jpg",
          title: this.t("explore.panagia"),
          description: this.t("explore.panagia1"),
          category: "land",
        },
        {
          image: "img/lemnos/limnos-nefeli.jpg",
          title: this.t("explore.nefeli"),
          description: this.t("explore.nefeli1"),
          category: "land",
        },
        {
          image: "img/lemnos/ammothines.jpg",
          title: this.t("explore.ammothines"),
          description: this.t("explore.ammothines1"),
          category: "land",
        },
        {
          image: "img/lemnos/platy.jpg",
          title: this.t("explore.platy"),
          description: this.t("explore.platy1"),
          category: "beach",
        },
        {
          image: "img/lemnos/keros.jpg",
          title: this.t("explore.keros"),
          description: this.t("explore.keros1"),
          category: "beach",
        },
        {
          image: "img/lemnos/kontias.jpg",
          title: this.t("explore.kontias"),
          description: this.t("explore.kontias1"),
          category: "land",
        },
        {
          image: "img/lemnos/polioxni.jpg",
          title: this.t("explore.polioxni"),
          description: this.t("explore.polioxni1"),
          category: "old",
        },
        {
          image: "img/lemnos/fanaraki.jpg",
          title: this.t("explore.fanaraki"),
          description: this.t("explore.fanaraki1"),
          category: "beach",
        },
        {
          image: "img/lemnos/moudros.jpg",
          title: this.t("explore.moudros"),
          description: "",
          category: "land",
        },
      ],
      selectedCategory: "all",
    };
  },
  computed: {
    filteredImages() {
      if (this.selectedCategory === "all") {
        return this.images;
      } else {
        return this.images.filter(
          (item) => item.category === this.selectedCategory
        );
      }
    },
  },
  methods: {
    filterImages(category) {
      this.selectedCategory = category;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
