<template>
  <section class="bg-primary text-white mb-0" id="mapAstron">
    <div class="container">
      <h3
        class="text-center text-uppercase text-white mb-0"
        style="font-size: 2rem; line-height: 2.5rem"
      >
      {{ $t("map.header") }}
      </h3>
      <hr class="shadow-lg" />
      <div class="card">
        <iframe
          title="Location on Map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d425.48754988933524!2d25.060816047928615!3d39.87724193255191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14af85171a0c135b%3A0xcd240e8b9efc3082!2zzrHPg8-Ez4HOv869IM6-zrXOvc6_zrTPh861zrnOvw!5e0!3m2!1sen!2sgr!4v1546825078907"
          height="600"
          style="min-width: 0; max-width: 100%"
          allowfullscreen
        ></iframe>
        <!-- Grid row -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LocationSection",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
