<template>
    <div :class="{ 'scrolled': isScrolled }" id="gotoTop">
        <router-link  class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" to="/" v-scroll-to="'#page-top'">
            <button class="bg-secondary js-scroll-trigger d-block text-center text-white rounded">
    <strong class="fa fa-chevron-up"></strong>
</button></router-link>

</div>
  </template>
  
  <script>
  export default {
    name: "NavigateToTopButton",
    props: {
      msg: String,
    },
    data() {
    return {
      isScrolled: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleImageClick(imageUrl) {
      // Set the modal image URL
      this.modalImageUrl = imageUrl;
      // Show the Bootstrap modal
      window.$('#imageModal').modal('show');
    },
    handleScroll() {
      // Get the scroll position
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;

      // Set a threshold, adjust as needed
      const scrollThreshold = 100;

      // Update isScrolled based on the scroll position
      this.isScrolled = scrollPosition > scrollThreshold;
    },
  },
  // ... (existing code)
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media screen and (max-width: 770px) {
  #gotoTop {
    display: none;
  }
  
}
.scrolled {
    position: absolute;
    left: 90%;
}
</style>
  